<template>
  <div class="about">
    <Navigation :path="path" />
    <img src="~img/about/01.png" />
    <div class="one">
      <div class="title-box">
        <img src="~img/index/02.png" class="title-icon" />
        <h4>企业介绍</h4>
        <img src="~img/index/03.png" class="title-icon" />
      </div>
      <p class="p1">
        爱因斯坦思维科学馆是科创星球旗下高品质 STEAM 科学教育机构，专注 3-12
        岁科学·思维融合教育，从孩子的好奇心出发，培养拥有强大思维能力和科学精神的未来英才。
      </p>
      <p>
        科创星球是科创星球（北京）教育科技有限公司简称，一家专注于青少年科学素养
        STEAM
        教育领域的专业机构，致力于通过聚合中国及全球前沿科学、技术领域的专家，积极探索
        STEAM
        教育、创客教育等新教育模式，运用科技的手段，为学前教育、公办中小学、青少年家庭提供以
        STEAM 教育为核心的全球创新人才培养方案，形成全球 STEAM
        教育资源联动共同体和中国特有的 STEAM
        教育生态系统，积极推动中国科技创新人才的培养。
      </p>
    </div>
    <div class="tow">
      <div class="title-box">
        <img src="~img/index/02.png" class="title-icon" />
        <h4>企业文化</h4>
        <img src="~img/index/03.png" class="title-icon" />
      </div>
      <h3><span>科创星球</span>愿景与使命</h3>
      <div class="conten hidden-xs-only">
        <img src="~img/about/02.png" />
        <p class="p1">让中国儿童都将受益于高品质 STEAM 教育</p>
        <p class="p2">
          创造蕴含乐趣、领悟和感动的教育文化，并实现社会贡献与企业繁荣
        </p>
      </div>
      <div class="web-conten hidden-sm-and-up">
        <p class="p1">让中国儿童都将受益于高品质 STEAM 教育</p>
        <p class="p2">
          创造蕴含乐趣、领悟和感动的教育文化，并实现社会贡献与企业繁荣
        </p>
      </div>
    </div>
    <div class="three">
      <div class="title-box">
        <img src="~img/index/02.png" class="title-icon" />
        <h4>科创星球核心价值</h4>
        <img src="~img/index/03.png" class="title-icon" />
      </div>
      <ul>
        <li>
          <img src="~img/about/03.png" alt="" />
          <p class="p1">非常规的思考</p>
          <p class="p2">
            打破框架，用崭新的视角看事情!深入广泛地看问题，提出创造性解决方案。
          </p>
        </li>
        <li>
          <img src="~img/about/05.png" alt="" />
          <p class="p1">提出问题</p>
          <p class="p2">
            先倾听，然后积极的表达自己的想法!提问再提问，做顶尖的专家。
          </p>
        </li>
        <li>
          <img src="~img/about/06.png" alt="" />
          <p class="p1">挑战到底</p>
          <p class="p2">
            制定高目标，坚持不懈地追求!即使失败也要从中学到东西，掌握执行力。
          </p>
        </li>
        <li>
          <img src="~img/about/07.png" alt="" />
          <p class="p1">团队合作</p>
          <p class="p2">
            从我做起，敞开心扉，靠近彼此!结成搭档，通过团队精神挑战最高的成就!
          </p>
        </li>
        <li>
          <img src="~img/about/08.png" alt="" />
          <p class="p1">为人正直</p>
          <p class="p2">
            具备坦率承认错误的勇气，努力做到真诚以待，待人接物言行一致。
          </p>
        </li>
      </ul>
    </div>
    <div class="pc-four hidden-xs-only">
      <div class="title-box">
        <img src="~img/index/02.png" class="title-icon" />
        <h4>人才招聘</h4>
        <img src="~img/index/03.png" class="title-icon" />
      </div>
      <img src="~img/about/09.png" class="tit-img">
    </div>
    <div class="web-four hidden-sm-and-up">
      <img src="~img/about/04.png" alt="" />
      <h2>
        一起成就梦想<br />
        我们期待您的加入
      </h2>
    </div>
    <consulting />
    <foot />
  </div>
</template>
<script>
import Navigation from "@/components/header.vue";
import footer from "@/components/footer.vue";
import consulting from "@/components/consulting.vue";

export default {
  components: {
    Navigation,
    foot: footer,
    consulting,
  },
  data() {
    return {
      path: "/about",
    };
  },
};
</script>

<style lang="less" scoped>
.about {
  .title-box {
    display: flex;
    justify-content: center;
    align-items: center;
    .title-icon {
      width: 0.8rem;
      height: 0.38rem;
    }
    h4 {
      font-size: 0.45rem;
      line-height: 0.8rem;
      margin-left: 0.47rem;
      margin-right: 0.47rem;
    }
  }
  .one {
    padding-top: 1.22rem /* 122/100 */;
    p {
      max-width: 14.91rem /* 1491/100 */;
      margin: auto;
      font-size: 0.25rem /* 25/100 */;
      line-height: 0.5rem /* 50/100 */;
      margin-top: 0.2rem /* 20/100 */;
    }
    .p1 {
      margin-top: 1rem /* 50/100 */;
    }
    @media (max-width: 750px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .tow {
    padding-top: 2.37rem /* 237/100 */;
    padding-bottom: 2.76rem /* 276/100 */;
    h3 {
      font-size: 0.4rem /* 40/100 */;
      color: #3e3a3a;
      text-align: center;
      margin-top: 0.62rem /* 92/100 */;
      margin-bottom: 1.02rem /* 102/100 */;
      span {
        color: #4f82e1;
      }
    }
    .conten {
      max-width: 14.77rem /* 1477/100 */;
      margin: auto;
      position: relative;
      p {
        font-size: 0.25rem /* 25/100 */;
        color: #3e3a3a;
        max-width: 3.11rem /* 311/100 */;
        line-height: 0.48rem /* 48/100 */;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }
      .p1 {
        left: 9.41%;
        text-align: center;
      }
      .p2 {
        right: 9.21%;
      }
    }
    .web-conten {
      font-size: 0.25rem /* 25/100 */;
      color: #3e3a3a;
      line-height: 0.48rem /* 48/100 */;
      text-align: center;
    }
  }
  .three {
    background: #eee;
    padding-top: 1.13rem /* 133/100 */;
    padding-bottom: 1.87rem /* 187/100 */;
    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-left: 1.88rem /* 188/100 */;
      padding-right: 2.04rem /* 204/100 */;
      padding-top: 1.46rem /* 146/100 */;
      text-align: center;
      li {
        width: 2.4rem /* 229/100 */;
        img {
          width: 2.04rem /* 204/100 */;
          margin: auto;
        }
        .p1 {
          margin-top: 0.56rem /* 56/100 */;
          font-size: 0.3rem /* 30/100 */;
          font-weight: 500;
          color: #000;
        }
        .p2 {
          font-size: 0.2rem /* 20/100 */;
          color: #666666;
          text-align: left;
          line-height: 0.32rem /* 32/100 */;
        }
      }
    }
    @media (max-width: 750px) {
      ul {
        padding-left: 20px;
        padding-right: 20px;
        li {
          margin-bottom: 20px;
        }
      }
    }
  }
  .pc-four {
    height: 8.54rem /* 854/100 */;
    background: url("~img/about/04.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 1.3rem /* 130/100 */;
    position: relative;
    .title-box {
      h4 {
        color: #fff;
      }
    }
    h2 {
      text-align: center;
      font-size: 0.57rem /* 57/100 */;
      color: #fff;
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%);
      text-shadow: 0.2rem 0rem 0.5rem #fff, -0.2rem 0rem 0.5rem #fff,
        0rem 0.2rem 0.5rem #fff, 0rem -0.2rem 0.5rem #fff;
    }
    .tit-img {
      width: 40%;
      margin: auto;
      margin-top: 0.4rem;
    }
  }
  .web-four {
    position: relative;
    h2 {
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 0.3rem /* 18/100 */;
      color: #fff;
    }
  }
}
</style>